<!--  -->
<template>
  <div class="notice-detail-page">
    <!-- <TopWrapper
      :topObj="{
        bgColor: '#fff',
        title: '反馈详情',
        txtColor: '#333',
        isBack: true,
        isMsg: false,
      }"
    /> -->
    <div class="main-wrapper">
      <div class="detail-info" v-if="detail">
        <div class="notice-title-info">
          <div class="notice-title">{{ detail.title }}</div>
          <p class="name-time">{{ detail.userName }}/{{ detail.createTime }}</p>
        </div>
        <div class="split-line"></div>
        <div class="content-info">{{ detail.content }}</div>

        <div class="link-txt">
          <div class="left-img-box" v-if="detail.urlArr.length">
            <p class="label">图片：</p>
            <van-image
              width="1.5rem"
              height="1.5rem"
              class="img"
              fit="cover"
              :src="itemUrl"
              :key="itemUrl"
              v-for="itemUrl in detail.urlArr"
            />
          </div>
        </div>
        <div class="remark" v-if="detail.remark">
          <p class="label">收到回复：</p>
          <div class="remark-info">{{ detail.remark }}</div>
        </div>
      </div>
      <van-empty v-else description="未获取到信息" />
    </div>
  </div>
</template>

<script>
import { feedbackInfo } from "@/api/staff";
import TopWrapper from "@/components/topWrapper/index.vue";

export default {
  data() {
    return {
      detail: null,
    };
  },

  computed: {},
 components: { TopWrapper },
  mounted() {
    if (this.$route.query) {
      let { code } = this.$route.query;
      if (code) {
        this.getNoticeInfo();
      }
    }
  },

  methods: {
    getNoticeInfo() {
      feedbackInfo({
        code: this.$route.query.code,
      }).then((res) => {
        this.detail = res.data;
        if (this.detail.imgMaterialCodes) {
          this.detail.urlArr = this.detail.imgMaterialCodes.split(",");
        } else {
          this.detail.urlArr = [];
        }
      });
    },
  },
  beforeDestroy() {
    clearInterval();
  },
};
</script>
<style lang="scss" scoped>
.notice-detail-page {
  background: #fafafa;
  min-height: 100%;
  .main-wrapper {
    width: 100%;
    .detail-info {
      padding-top: 0.3rem;
      width: 100%;
      background: #ffffff;
      border-radius: 0.4rem;
      .notice-title-info {
        padding: 0.3rem;
        width: 100%;
        .notice-title {
          width: 100%;
          text-align: center;
          font-size: 0.34rem;
          font-weight: 500;
          color: #000000;
          line-height: 0.48rem;
        }
        .name-time {
          text-align: center;
          font-size: 0.26rem;
          margin-top: 0.24rem;
          font-weight: 400;
          color: #666666;
          line-height: 0.28rem;
        }
      }
      .split-line {
        width: 100%;
        height: 0.2rem;
        background: #fafafa;
      }
      .content-info {
        padding: 0.3rem;
        width: 100%;
      }
      .label {
        height: 0.32rem;
        font-size: 0.28rem;
        font-weight: 500;
        color: #333333;
        line-height: 0.32rem;
        margin-bottom: 0.3rem;
      }
      .link-txt {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0.3rem;
        padding-bottom: 0rem;

        .left-img-box {
          margin-top: 0.3rem;
          .img {
            margin-right: 0.2rem;
          }
        }
      }
      .remark {
        padding: 0.3rem;
        color: #999;
      }
    }
  }
}
</style>
